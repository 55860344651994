/* Order Edit, Vue Component */
<template>
  <div>
    <order-summary v-if="entity && entity.Id && !loading"
      :title="`Order - ${entity.Reference} - ${entity.MemberId_FullName}`"
      :order="entity"></order-summary>
    <v-card v-if="entity && !loading" id="order-edit" class="entity-edit mt-6">
        <v-card-title>Update Customs Info</v-card-title>

        <v-form ref="form" class="mb-6">
        <v-simple-table dense>
          <thead>
            <tr>
              <th colspan="2">Details</th>
            </tr>
          </thead>
          <tbody>

        <tr>
          <td>Retailer</td>
          <td><v-text-field
        label="Retailer"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'RetailerName').map(x => x.error)"
        v-model="entity.RetailerName"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Retailer Reference</td>
          <td><v-text-field
        label="Retailer Reference"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'RetailerReference').map(x => x.error)"
        v-model="entity.RetailerReference"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Retailer Tracking Reference</td>
          <td><v-text-field
        label="Retailer Tracking Reference"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'RetailerTrackingReference').map(x => x.error)"
        v-model="entity.RetailerTrackingReference"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Retailer Order Date</td>
          <td><DateField v-model="entity.RetailerOrderDate" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'RetailerOrderDate').map(x => x.error)" /></td>
        </tr>

        <tr>
          <td>Retailer Expected Delivery Date</td>
          <td><DateField v-model="entity.RetailerExpectedDeliveryDate" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'RetailerExpectedDeliveryDate').map(x => x.error)" /></td>
        </tr>

        <tr>
          <td>Retailer Delivery Charge</td>
          <td><v-text-field
        label="Retailer Delivery Charge"
        solo-inverted
        prefix="£"
        type="number"
        flat
        :error-messages="serverErrors.filter(x => x.field === 'RetailerDeliveryCharge').map(x => x.error)"
        v-model="entity.RetailerDeliveryCharge"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Importation Customs Declaration</td>
          <td><v-checkbox v-model="entity.ImportationCustomsDeclaration" :color="(entity.ImportationCustomsDeclaration ? 'success' : 'secondary')"></v-checkbox></td>
        </tr>

        <tr>
          <td>Original Retailer Documentation</td>
          <td><v-checkbox v-model="entity.OriginalRetailerDocumentation" :color="(entity.OriginalRetailerDocumentation ? 'success' : 'secondary')"></v-checkbox></td>
        </tr>

        <tr>
          <td>Item Value</td>
          <td><v-text-field
        label="Item Value"
        solo-inverted
        prefix="£"
        type="number"
        flat
        :error-messages="serverErrors.filter(x => x.field === 'ItemValue').map(x => x.error)"
        v-model="entity.ItemValue"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Item Description</td>
          <td><v-text-field
        label="Item Description"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'ItemDescription').map(x => x.error)"
        v-model="entity.ItemDescription"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Dispatched From UK</td>
          <td><v-checkbox v-model="entity.DispatchedFromUK" :color="(entity.DispatchedFromUK ? 'success' : 'secondary')"></v-checkbox></td>
        </tr>

        <tr>
          <td>Originating Country</td>
          <td><v-text-field
        label="Originating Country"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'OriginatingCountry').map(x => x.error)"
        v-model="entity.OriginatingCountry"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Commodity Code</td>
          <td><v-text-field
        label="Commodity Code"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'CommodityCode').map(x => x.error)"
        v-model="entity.CommodityCode"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>


          </tbody>
        </v-simple-table>
        </v-form>

        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-alert
            v-if="serverErrorMessage"
            type="error"
          >
            {{ serverErrorMessage }}
          </v-alert>
        </v-card-text>

        <v-card-text class="d-flex align-center justify-center flex-wrap pb-6">
          <v-btn
            color="primary"
            class="me-3"
            @click="Save"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiContentSaveOutline  }}
            </v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn
            color="secondary"
            class="me-3"
            @click="Back"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>

        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import DateField from '@/views/helpers/DateField'
import OrderStatusLookup from '@/views/orderstatus/OrderStatusLookup'
import ProductLookup from '@/views/product/ProductLookup'
import ManifestLookup from '@/views/manifest/ManifestLookup'
import MemberLookup from '@/views/member/MemberLookup'
import AddressLookup from '@/views/address/AddressLookup'
import OrderSummary from './components/OrderSummary'

import {
  mdiContentSaveOutline,
  mdiChevronLeft
} from '@mdi/js'
import { ref, watch, computed, expose } from '@vue/composition-api'

export default {
  components: {
    DateField,
    OrderStatusLookup,
		ProductLookup,
		ManifestLookup,
		MemberLookup,
		AddressLookup,
    OrderSummary
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => {
      return store.state.app.Order;
    });
    const loading = ref(false);
    const form = ref(null);
    const serverErrors = ref([]);
    const serverErrorMessage = ref(null);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchOrder', {
          id: id
        })
        .then(response => {
          loading.value = false;
        })
        .catch(error => {
          loading.value = false;
          console.log(error);
          router.push({ name: 'order-list', params: { id: route.value.params.id } });
        })
    }

    // fetch the user information when params change
    watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);


    const Save = function(e) {

      serverErrorMessage.value = null;
      serverErrors.value = [];

      const valid = form.value.validate();

      if (!valid) {
        return;
      }

      loading.value = true;

      store
        .dispatch('app/updateOrder', {
          id: route.value.params.id,
          entity: entity.value
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'order-view', params: { id: response.data.Id } });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
    };

    const Back = () => {
      router.go(-1);
    };

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    return {
      Back,
      form,
      Save,
      loading,
      entity,
      Display,
      icons: {
        mdiContentSaveOutline ,
        mdiChevronLeft,
        mdiChevronLeft
      },
      validators,
      serverErrorMessage,
      serverErrors
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-edit::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding-right: 0;
}
.entity-edit::v-deep .v-data-table th:last-child {
  width: 66%;
}
.entity-edit::v-deep  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-messages {
  min-height: unset;
}
.entity-edit::v-deep .v-input .v-messages.error--text {
  margin-bottom: 8px;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-text-field__details {
  min-height: unset;
  margin-bottom: 0;
}
.entity-edit::v-deep .v-input:not(.error--text) .v-input__slot {
  margin: 0;
  border-radius: 0;
}
.entity-edit::v-deep .v-input--selection-controls {
  margin: 10px;
}
</style>

